import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { TextButton } from 'wix-ui-tpa';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeftSmall';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRightSmall';
import { classes, st } from './BackButton.st.css';
import { DataHooks } from './constants';
import { useHistory } from '../../Hooks/useHistory/useHistory';
import { BackButtonViewModel } from '../../ViewModel/backButtonViewModel/backButtonViewModel';

export interface BackButtonProps {
  viewModel?: BackButtonViewModel;
}

export const BackButton: React.FC<BackButtonProps> = ({ viewModel }) => {
  const { isRTL, isPreview } = useEnvironment();
  const { back, length } = useHistory();

  const showBackButton = !!viewModel && !isPreview && length > 1;

  return (
    <div className={st(classes.root, { isRTL })}>
      {showBackButton && (
        <TextButton
          data-hook={DataHooks.BackButton}
          onClick={back}
          className={classes.button}
          prefixIcon={
            isRTL ? <ChevronRight aria-hidden /> : <ChevronLeft aria-hidden />
          }
        >
          {viewModel!.label}
        </TextButton>
      )}
    </div>
  );
};
