import React, { useEffect } from 'react';
import Header from './Header/Header';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import { WidgetProps, useEnvironment } from '@wix/yoshi-flow-editor';
import { classes, st } from './Widget.st.css';
import EmptyState from './EmptyState/EmptyState';
import { BottomSectionStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Spinner } from 'wix-ui-tpa';
import { CalendarActionsProvider } from '../Hooks/useCalendarActions';
import { CalendarFlowProvider, FlowElements, useFlow } from '../Hooks/useFlow';
import RescheduledBookingDetails from './RescheduledBookingDetails/RescheduledBookingDetails';
import Dialog from './Dialog/Dialog';
import Toast from './Toast/Toast';
import Sidebar from './Sidebar/Sidebar';
import DailyTimeSlotsMonthlyPickerLayout from './DailyLayout/DailyLayout';
import WeeklyTimeSlotsLayout from './WeeklyLayout/WeeklyTimeSlotsLayout/WeeklyTimeSlotsLayout';
import WeeklyTimetableLayout from './WeeklyLayout/WeeklyTimetableLayout/WeeklyTimetableLayout';
import {
  DESKTOP_WIDGET_MIN_WIDTH,
  MOBILE_WIDGET_MIN_WIDTH,
} from '../../../constants/constants';
import { useSettingsParams, PresetProvider } from '../Hooks/useSettingsParams';
import { BackButton } from './BackButton/BackButton';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const WidgetWrapper: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel, backButtonViewModel } = props;

  return (
    <>
      {widgetViewModel?.emptyStateViewModel ? (
        <>
          <BackButton viewModel={backButtonViewModel} />
          <EmptyState {...widgetViewModel?.emptyStateViewModel} />
        </>
      ) : (
        <CalendarFlowProvider value={widgetViewModel.focusedElement}>
          <PresetProvider value={widgetViewModel.preset}>
            <CalendarActionsProvider value={props}>
              <Widget {...props} />
            </CalendarActionsProvider>
          </PresetProvider>
        </CalendarFlowProvider>
      )}
    </>
  );
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const {
    isMobile,
    isRTL,
    dimensions: { width, height },
    isEditor,
  } = useEnvironment();
  const calendarLayout = settings.get(settingsParams.calendarLayout);

  useEffect(() => {
    const widgetMinWidth = isMobile
      ? MOBILE_WIDGET_MIN_WIDTH
      : DESKTOP_WIDGET_MIN_WIDTH;
    if (isEditor && typeof width === 'number' && width < widgetMinWidth) {
      props.host.resizeComponent &&
        props.host.resizeComponent({
          width: widgetMinWidth,
          height,
          mobileResize: isMobile,
        });
    }
  }, [width]);

  useEffect(() => {
    if (props.widgetViewModel.status === BottomSectionStatus.LOADING) {
      props.initializeWidget();
    }
  }, [props.widgetViewModel.status]);

  useEffect(() => {
    if (props.widgetViewModel.status === BottomSectionStatus.LOADED) {
      props.initializeWidget();
    }
  }, [calendarLayout]);

  const getWidgetLoader = () => (
    <div data-hook="widget-loader" className={classes.loader}>
      <Spinner diameter={50} isCentered={true} />
    </div>
  );

  const getWidgetContentByStatus = () => {
    switch (props.widgetViewModel.status) {
      case BottomSectionStatus.LOADING:
        return getWidgetLoader();
      case BottomSectionStatus.LOADED:
        return <BottomSectionContent {...props} />;
      default:
        return getWidgetLoader();
    }
  };

  return (
    <div
      className={st(classes.root, {
        isRTL,
        isMobile,
        textAlignment: settings.get(settingsParams.textAlignment),
        columnAlignment: settings.get(settingsParams.columnAlignment),
        layout: settings.get(settingsParams.calendarLayout),
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook="BookingCalendar-wrapper"
    >
      <BackButton viewModel={props.backButtonViewModel} />
      <div data-hook="top-section" className={classes.topSection}>
        <div
          data-hook="top-section-content"
          className={classes.topSectionContentContainer}
        >
          {props.rescheduleDetailsViewModel && (
            <RescheduledBookingDetails
              viewModel={props.rescheduleDetailsViewModel}
            />
          )}
          <Header viewModel={props.headerViewModel!} />
        </div>
      </div>
      <div data-hook="bottom-section" className={classes.bottomSection}>
        <div
          data-hook="bottom-section-content"
          className={classes.bottomSectionContentContainer}
        >
          {getWidgetContentByStatus()}
        </div>
      </div>
      <Dialog viewModel={props.dialogViewModel!} />
      <Toast viewModel={props.toastViewModel!} />
    </div>
  );
};

const BottomSectionContent: React.FC<ControllerProps> = (props) => {
  const bottomSectionRef = useFlow(FlowElements.BOTTOM_SECTION);
  const { sidebarViewModel } = props;

  return (
    <div className={classes.bottomSectionContent} ref={bottomSectionRef}>
      <Body {...props} />
      {sidebarViewModel ? <Sidebar viewModel={sidebarViewModel} /> : null}
    </div>
  );
};

const Body: React.FC<ControllerProps> = ({
  widgetViewModel: { errorNotificationText },
  bodyViewModel,
}) => {
  const {
    weeklyTimeSlotsLayout,
    dailyTimeSlotsMonthlyPickerLayout,
    weeklyTimetableLayout,
  } = bodyViewModel!;

  return (
    <div className={classes.body} data-hook="body">
      {weeklyTimeSlotsLayout ? (
        <WeeklyTimeSlotsLayout
          viewModel={weeklyTimeSlotsLayout}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
      {dailyTimeSlotsMonthlyPickerLayout ? (
        <DailyTimeSlotsMonthlyPickerLayout
          viewModel={dailyTimeSlotsMonthlyPickerLayout}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
      {weeklyTimetableLayout ? (
        <WeeklyTimetableLayout
          viewModel={weeklyTimetableLayout}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
    </div>
  );
};

export default WidgetWrapper;
